import React from "react";

import Layout from "../components/Layout";
import Section from "../components/Section";
import Promo from "../components/Promo";

const Guide = (props) => {
	return (<Layout location={props.location}>
		<Section title={"Soodustused"}>
			<div className={"flex flex-col text-sm max-w-4xl leading-5"}>
				<p className={"mb-6"}>Hea dieedisõber! </p>
				<p>
					Dr Simeonsi dieedi meeskonna jaoks on kliendid ning nende positiivne
					kogemus dieediprogrammi läbimisel väga olulised! Selleks, et
					saaksime Sinu häid tulemusi ka teiste inimesteni viia, premeerime
					Sind suurima tänuga lisasoodustusega järgmiselt dieedipaketi ostult.
					Vali välja endale sobivaim lahendus ning vajadusel küsi lisainfot
					meie konsultantidelt.
				</p>
				<hr className="border-2 border-green rounded mx-auto my-10 w-full max-w-md"/>
				<h3
					className={"text-xl md:text-2xl font-semibold text-center mb-12 leading-7 max-w-xl text-center max-w-2xl m-auto"}
				>
					Soovita Dr Simeonsi dieeti ka teistele huvilistele ning saad{" "}
					<span className={"text-green"}>25%</span> soodustust uue
					dieedipaketi ostult
				</h3>
				<p>
					Kui Sinu sõber tunneb huvi Dr Simeonsi dieedi esmakordse läbimise
					vastu, siis peaks ta lisama tellimusankeeti Sinu täisnime! Nii
					võidate dieedipaketi hinnas mõlemad.
				</p>
				<p className={"mt-6"}>Kuidas see töötab?</p>
				<ul className={"mt-6 list-disc pl-4"}>
					<li>
						<p>
							Sinu sõber täidab tellimusankeeti ning lisab ‘Kupongi kood või soovitaja ees- ja
							perenimi’ lahtrisse Sinu täisnime. Miks? Sest nii saab sõber dieedipaketi esmasel
							soetamisel ostult soodustust <span className={"text-green"}>10%</span> ning soetab
							dieedipaketi hinnaga <span className={"text-green"}>86,4€</span> (tavahind <span
							className={"text-green"}>96€</span>).
						</p>
					</li>
					<li>
						<p>
							Iga kalendrikuu lõpus loetleme kokku, mitu korda on uued dieedisõbrad Sinu nimi dieedipaketi
							soetamisel kasutanud ning iga korra eest saad Sina <span
							className={"text-green"}>25%</span> soodustust järgmiselt dieediringilt.
						</p>
					</li>
				</ul>
				<p className={"mt-6"}>
					Ehk kui dieedipaketi on Sinu nimega soetanud üks sõber, saad
					soodustust 25%, kui aga 4 sõpra, siis on järgmine dieediring Sulle
					sootuks <span className={"text-green"}>TASUTA!</span>
				</p>
				<p className={"mt-6"}>
					Kokkuvõtte koos personaalsete allahindluskoodidega saadame kord
					kuus. Soodustusel pole aegumistähtaega, seega saad ise vabalt
					valida, millal järgmise ringiga algust teha.
				</p>
				<hr className="border-2 border-green rounded mx-auto my-10 w-full max-w-md"/>
				<h3
					className={"text-xl md:text-2xl font-semibold text-center mb-12 leading-7 max-w-xl text-center max-w-2xl m-auto"}
				>
					Jaga meiega oma Dr Simeonsi dieedi kogemuslugu ning saad{" "}
					<span className={"text-green"}>50%</span> soodustust uue
					dieedipaketi ostult
				</h3>
				<p>
					Selleks, et saaksime Sinu edukalt läbitud Dr Simeonsi dieedi
					suurepäraseid tulemusi ka teiste huvilistega jagada, vajame
					järgmist:
				</p>
				<ul className={"mt-6 list-disc pl-4"}>
					<li>Kirjeldust Sinu Dr Simeonsi dieedi kogemusest (u A4 mahus).</li>
					<li>
						Hea kvaliteediga täispikkuses pilte Sinust enne ja pärast dieedi
						läbimist
					</li>
					<li>
						Nõusolekut Sinult jagada kogemuslugu Dr Simeonsi dieedi kanalites
						(koduleht, sotsiaalmeedia)
					</li>
				</ul>
				<p className={"mt-6"}>
					Oluline eeldus on, et oled läbinud Dr Simeonsi dieedi reegleid
					järgides ning oled kaotanud 23 päevaga vähemalt 5 kilo või 40
					päevaga vähemalt 8 kilo ülekaalu.
				</p>
				<p className={"mt-6"}>
					PS! Materjalide kvaliteedi mittesobivuse korral on Dr Simeonsi
					dieedi meeskonnal õigus kogemusloo avaldamisest ja soodustuse
					jagamisest keelduda.
				</p>
				<h3
					className={"text-xl md:text-2xl font-semibold text-center leading-7 max-w-xl text-center max-w-3xl m-auto mt-12"}
				>
					Huvi korral kirjuta meile{" "}
					<span className={"text-green"}>contact@simeonsidieet.ee</span> ning
					saada oma kogemuslugu koos pildimaterjaliga. Saad vastu personaalse
					sooduskoodi järgmise dieedipaketi tellimiseks.
				</h3>
				<hr className="border-2 border-green rounded mx-auto my-10 w-full max-w-md"/>
				<h3
					className={"text-xl md:text-2xl font-semibold text-center mb-12 leading-7 m-auto"}
				>
					Osta korraga mitu dieedipaketti ning saad lisapaketi tasuta
				</h3>
				<p>
					Läbi Dr Simeonsi dieet koos sõpradega. Nii on lihtsam ning saad
					dieedipaketi hulgitellimuse korral soodustust! Ostes 4 dieedipaketti
					saad 1 dieedipaketi tasuta.
				</p>
				<p className="text-lg font-semibold text-center mt-8">
					Kokkuleppe tegemiseks võta meiega ühendust{" "}
					<a href="mailto:contact@simeonsidieet.ee" className="text-green">
						contact@simeonsidieet.ee
					</a>
				</p>
			</div>
		</Section>
		<Promo/>
	</Layout>);
};

export default Guide